var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"customization__form"},[_c('button',{staticClass:"customization__toggle",on:{"click":function($event){_setup.isFormShow = !_setup.isFormShow}}},[_vm._v("\n    "+_vm._s(_setup.t('CustomizationForm#Toggle form'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"block__title"},[_c('div',{staticClass:"title"},[_c('h2',[_vm._v(_vm._s(_setup.t('CustomizationForm#Title Customization form')))])])]),_vm._v(" "),_c('div',{staticClass:"row small-align-justify customization__row"},[_c('div',{staticClass:"customization__block customization__block--preview"},[_c('div',{staticClass:"disable-overlay"}),_vm._v(" "),_c('h3',{staticClass:"customization__title"},[_vm._v("\n        "+_vm._s(_setup.t('CustomizationForm#Subtitle Your booking engine'))+"\n      ")]),_vm._v(" "),_c(_setup.AllRooms)],1),_vm._v(" "),_c('div',{class:[
        'customization__block customization__block--form',
        { hide: _setup.isFormShow }
      ]},[_c('v-container',[_c('v-row',[_c('v-col',[_c('div',{staticClass:"fieldset__item",class:{ 'mb-4': !_setup.isDesktop }},[_c('div',{staticClass:"fieldset__item-title"},[_c('p',{staticClass:"title"},[_vm._v("\n                  "+_vm._s(_setup.t('CustomizationForm#Mode Dark mode'))+"\n                ")])]),_vm._v(" "),_c(_setup.fieldSwitcher,{attrs:{"value":_setup.customization.isDarkMode},on:{"input":(value) => _setup.updateField('isDarkMode', value)}})],1),_vm._v(" "),_vm._l((_setup.store.getters.customizationColorFields),function(value,name){return _c('div',{key:name,staticClass:"fieldset__item"},[(name === 'colorBtnBg')?[_c('div',{staticClass:"fieldset__item-title"},[_c('p',{staticClass:"title"},[_vm._v("\n                    "+_vm._s(_setup.t('CustomizationForm#Field colorPrimary'))+"\n                  ")])]),_vm._v(" "),_c(_setup.fieldColor,{attrs:{"colorDefault":_setup.customization[name]},on:{"color-change":(value) => _setup.debounceUpdateField(name, value)}})]:_vm._e()],2)}),_vm._v(" "),_c('v-btn',{class:[
                'text-none',
                {
                  'mb-10': _setup.isDesktop && !_setup.isFontsConfigExpanded,
                  'mb-3': _setup.isFontsConfigExpanded,
                  'my-6': !_setup.isDesktop
                }
              ],attrs:{"color":"primary","ripple":false,"block":"","large":"","outlined":"","tile":""},on:{"click":function($event){_setup.isFontsConfigExpanded = !_setup.isFontsConfigExpanded}}},[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"width":"100%"}},[_c('div',{class:{
                    'text-h5': _setup.isDesktop,
                    'text-body-1 font-weight-bold': !_setup.isDesktop
                  }},[_vm._v("\n                  "+_vm._s(_setup.t('CustomizationForm#FontsAccordion Fonts customization'))+"\n                ")]),_vm._v(" "),_c('i',{staticClass:"d-block icon-angle-down",class:{ 'rotate-180': _setup.isFontsConfigExpanded },staticStyle:{"font-size":"0.5rem"}})])]),_vm._v(" "),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_setup.isFontsConfigExpanded),expression:"isFontsConfigExpanded"}],staticClass:"fieldset"},[_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"mb-3",class:{
                      'text-h6 font-weight-bold': _setup.isDesktop,
                      'text-body-2': !_setup.isDesktop
                    }},[_vm._v("\n                    "+_vm._s(_setup.t('CustomizationForm#PrimaryFont Primary font'))+"\n                  ")]),_vm._v(" "),(_setup.customization.fontFamilyPrimary)?_c(_setup.CustomizationFontDropdown,{attrs:{"selected-font":_setup.customization.fontFamilyPrimary},on:{"input":(value) => _setup.updateField('fontFamilyPrimary', value)}}):_vm._e(),_vm._v(" "),_c(_setup.fieldRadioGroup,{attrs:{"data":{
                      default: _setup.customization.fontSizePrimary,
                      list: _setup.fontSizes
                    }},on:{"changeValue":(value) => _setup.updateField('fontSizePrimary', value)}})],1),_vm._v(" "),(_setup.customization.fontFamilyBtn)?_c('div',{staticClass:"mb-5"},[_c('div',{staticClass:"mb-3",class:{
                      'text-h6 font-weight-bold': _setup.isDesktop,
                      'text-body-2': !_setup.isDesktop
                    }},[_vm._v("\n                    "+_vm._s(_setup.t('CustomizationForm#ButtonFont Button font'))+"\n                  ")]),_vm._v(" "),_c(_setup.CustomizationFontDropdown,{attrs:{"selected-font":_setup.customization.fontFamilyBtn},on:{"input":(value) => _setup.updateField('fontFamilyBtn', value)}}),_vm._v(" "),_c(_setup.fieldRadioGroup,{attrs:{"data":{
                      default: _setup.customization.fontSizeBtn,
                      list: _setup.fontSizes
                    }},on:{"changeValue":(value) => _setup.updateField('fontSizeBtn', value)}})],1):_vm._e(),_vm._v(" "),(_setup.customization.fontFamilyTitle)?_c('div',{staticClass:"mb-8"},[_c('div',{staticClass:"mb-3",class:{
                      'text-h6 font-weight-bold': _setup.isDesktop,
                      'text-body-2': !_setup.isDesktop
                    }},[_vm._v("\n                    "+_vm._s(_setup.t('CustomizationForm#TitleFont Title font'))+"\n                  ")]),_vm._v(" "),_c(_setup.CustomizationFontDropdown,{attrs:{"selected-font":_setup.customization.fontFamilyTitle},on:{"input":(value) => _setup.updateField('fontFamilyTitle', value)}}),_vm._v(" "),_c(_setup.fieldRadioGroup,{attrs:{"data":{
                      default: _setup.customization.fontSizeTitle,
                      list: _setup.fontSizes
                    }},on:{"changeValue":(value) => _setup.updateField('fontSizeTitle', value)}})],1):_vm._e(),_vm._v(" "),_c('v-divider',{staticClass:"mb-6"})],1)]),_vm._v(" "),_c('div',{staticClass:"fieldset__item",class:{ 'mb-5': !_setup.isDesktop }},[_c('div',{staticClass:"fieldset__item-title"},[_c('p',{staticClass:"title"},[_vm._v("\n                  "+_vm._s(_setup.t('CustomizationForm#Logo Select new logo'))+"\n                ")])]),_vm._v(" "),_c(_setup.fieldImage,{attrs:{"dimensions":{ w: 600, h: 600 },"imageUrl":_setup.customization.logoUrl},on:{"image-chosen":(value) => _setup.updateField('logoUrl', value)}})],1),_vm._v(" "),_c('div',{staticClass:"fieldset__item",class:{ 'mb-5': !_setup.isDesktop }},[_c('div',{staticClass:"fieldset__item-title"},[_c('p',{staticClass:"title"},[_vm._v("\n                  "+_vm._s(_setup.t(
                      'CustomizationForm#BackgroundImage Select background image'
                    ))+"\n                ")])]),_vm._v(" "),_c(_setup.fieldImage,{attrs:{"imageUrl":_setup.customization.bgUrl,"dimensions":{ w: 1280, h: 1024 },"removable":Boolean(_setup.customization.bgUrl)},on:{"remove":function($event){return _setup.store.commit('UPDATE_CUSTOMIZATION_CONFIG', {
                    bgUrl: ''
                  })},"image-chosen":(value) => _setup.updateField('bgUrl', value)}})],1)],2)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"text-none my-4",attrs:{"color":"primary","loading":_setup.store.getters.loading,"disabled":_setup.store.getters.loading,"ripple":false,"block":"","tile":"","x-large":""},on:{"click":function($event){return _setup.store.dispatch('sendCustomizationData')}}},[_vm._v("\n              "+_vm._s(_setup.t('CustomizationForm#Save Save'))+"\n            ")]),_vm._v(" "),_c('v-btn',{staticClass:"text-none mb-4",attrs:{"color":"primary","loading":_setup.store.getters.loading,"disabled":_setup.store.getters.loading,"ripple":false,"block":"","outlined":"","tile":"","x-large":""},on:{"click":function($event){$event.preventDefault();return _setup.debounceLoadCustomization.apply(null, arguments)}}},[_vm._v("\n              "+_vm._s(_setup.t('CustomizationForm#Button Reload'))+"\n            ")]),_vm._v(" "),_c('v-btn',{staticClass:"text-none mb-4",attrs:{"color":"primary","loading":_setup.store.getters.loading,"disabled":_setup.store.getters.loading,"ripple":false,"block":"","outlined":"","tile":"","x-large":""},on:{"click":function($event){$event.preventDefault();return _setup.confirmResetCustomization.apply(null, arguments)}}},[_vm._v("\n              "+_vm._s(_setup.t('CustomizationForm#Button Factory reset'))+"\n            ")])],1)],1)],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }